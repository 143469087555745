"use client";

import React, { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
const Template = ({
  children
}: {
  children: ReactNode;
}) => {
  const queryClient = new QueryClient();
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="Template" data-sentry-source-file="template.tsx">{children}</QueryClientProvider>;
};
export default Template;